<template>
<!-- 设备报文 -->
  <div class="box100">
    <el-dialog title="报文" :visible.sync="msgDialog" width="900px" center class="gyDialog fromDialog" :close-on-click-modal="false" :before-close="newMsgCancel">
      <div class="msg_content">
        <div class="mess_box">
          <div class="ud_msg" :class="msgInformation.emdisok === true ? 'ud_msg2' : 'ud_msg1'">
            <span v-if="msgInformation.emdisok == false">离线</span>
            <span v-if="msgInformation.emdisok == true">在线</span>
          </div>
          <div class="udi_m1" style="margin: 0 20px;"><span>设备类型: {{ msgInformation.emdname }}</span></div>
          <div class="udi_m2"><span>IMEI码: {{ msgInformation.emdimei }}</span></div>
        </div>
        <div class="flexCB">
          <div>
            <el-date-picker
              v-model="msgDate"
              type="daterange"
              value-format="yyyy-MM-dd"
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              class="mdb">
            </el-date-picker>
            <el-button class="settingSearch" @click="handleSearch">搜索</el-button>
            <el-button class="settingReset" @click="handleReset">重置</el-button>
            <el-button class="el-icon-refresh settingRef" @click="handleRefmsg">查看最新</el-button>
          </div>
          <div class="msgswich"  :class="{ msgswich1:refreshMsg == true,msgswich2:refreshMsg == false }">实时刷新
            <el-switch v-model="refreshMsg" v-if="refreshMsg == true"  @change="handleMsgSwitch"></el-switch>
            <el-switch v-model="refreshMsg" v-if="refreshMsg == false" @change="handleMsgSwitch"></el-switch>
          </div>
        </div>
        <div class="mag_info">
          <p style="margin:20px 0;" v-for="(item, index) in msgInformation.emdmsgd" :key="index">
            <span class="msg_time">{{ item.time }}</span>
            <span class="msg_data">{{ item.data }}</span>
          </p>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getEqumsgData } from '@/http/api/equip'
export default {
  name: 'equipMsg',
  props: ['messageInfo'],
  data () {
    return {
      msgDialog: true,
      msgInformation: {},
      msgDate: null,
      refreshMsg: false,
      timer: null
    }
  },
  mounted () {
    this.getMsgInfo()
  },
  methods: {
    getMsgInfo () {
      // this.refreshMsg = false
      let staTime, endTime = ''
      if (this.msgDate != null) {
        staTime = this.msgDate[0]
        endTime = this.msgDate[1]
      }
      const params = {
        equid: this.messageInfo.equid,
        stime: staTime,
        etime: endTime
      }
      getEqumsgData(params).then(res => {
        if (res.status === '1') {
          this.msgInformation = res.data
        }
      })
    },
    /* 搜索 */
    handleSearch () {
      this.getMsgInfo()
    },
    /* 重置 */
    handleReset () {
      this.msgDate = null
      this.getMsgInfo()
    },
    /* 更新 */
    handleRefmsg () {
      this.getMsgInfo()
    },
    handleMsgSwitch () {
      if (this.refreshMsg === true) {
        this.timer = setInterval(() => {
          this.getMsgInfo()
        }, 5000)
      } else {
        this.clear()
      }
    },
    clear () {
      clearInterval(this.timer)
      this.timer = null
      this.refreshMsg = false
    },
    /* 取消 */
    newMsgCancel () {
      this.clear()
      this.$emit('closeMsg')
    }
  }
}
</script>

<style scoped>
.mess_box{
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #F0F3F8;
  padding: 0px 0px 20px;
  margin-bottom: 20px;
}
.ud_msg {
  width: 70px;
  height: 40px;
  opacity: 1;
  border-radius: 4px;
  text-align: center;
  line-height: 40px;
}
.ud_msg1 {
  color: #E2335C;
  background: rgba(226, 51, 92, 0.2);
}
.ud_msg2 {
  color: #1FDB8A;
  background: rgba(31, 219, 138, 0.2);
}
.udi_m1,.udi_m2 {
  width: 40%;
  height: 40px;
  background: #F6F9FF;
  opacity: 1;
  border-radius: 4px;
  line-height: 40px;
  padding: 0 10px;
  box-sizing: border-box;
}
.udi_m2 {
  width: 45%;
}
.msgswich {
  width: 140px;
  height: 35px;
  background: rgba(52, 118, 243, 0.2);
  opacity: 1;
  border-radius: 4px;
  color: #3476F3;
  text-align: center;
  line-height: 35px;
}
.msgswich1 >>>.el-switch__core {
  background: #3476F3;
  border: 1px solid #3476F3;
}
.msgswich2 >>>.el-switch__core {
  background: #8291A1;
  border: 1px solid #8291A1;
}
.settingRef {
  width: 100px;
  height: 35px;
  background: #196DF7;
  opacity: 1;
  border-radius: 4px;
  color: #fff;
  border: none;
  cursor: pointer;
  text-align: center;
  padding: 0px 0px;
}
.mag_info {
  font-size: 14px;
  height: 500px;
  overflow-y: scroll;
  scrollbar-width: none;  /*火狐兼容*/
}
.mag_info::-webkit-scrollbar {
  width: 0px; /* 谷歌兼容 */
}
.msg_time {
  color: #333333;
  margin-right: 10px;
  font-weight: bold;
  font-size: 14px;
}
.msg_data {
  color: #8291A1;
  font-size: 14px;
}
.mdb >>> .el-range-separator{
  line-height: 28px;
}
</style>
