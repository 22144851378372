<template>
  <div class="setting_main equipListCont">
    <div class="equip_top">
      <div class="equip_num">
        <p class="eqTitle">设备总数</p>
        <p class="eqNumber">{{ equcount }}</p>
        <div class="eqChart">
          <div style="width:100%;height:100%;"  ref="eqCountChart"></div>
        </div>
      </div>
      <div class="equip_count">
        <div ref="eqNumChart" style="width:100%;height:100%;"></div>
      </div>
    </div>

    <div class="equip_content">
      <div class="equip_contentBox">
        <div class="btn_group">
          <div v-if="roleBtnArray[0].power" class="iconfont iconjia settingAdd" @click="handleAdd"><span>新增</span></div>
          <div v-if="roleBtnArray[3].power" class="iconfont icondaochu settingExport" @click="exportHand"><span>导出</span></div>
          <div class="el-icon-close settingDelete" v-if="deleteBtnShow && roleBtnArray[1].power" @click="deleteSelect"><span>删除</span></div>
        </div>
        <div class="flexAC" style="margin: 20px 0px;">
          <el-input v-model="equname"  placeholder="请输入搜索内容" class="contInput"></el-input>
          <el-select v-model="transmission" placeholder="传输方式" class="utG setSe">
            <el-option v-for="(item) in tranList" :key="item.dictid"
                       :label="item.datavalue"
                       :value="item.dictid">
            </el-option>
          </el-select>
          <el-select v-model="equipState" placeholder="设备状态" class="utF setSe">
            <el-option
              v-for="(item,index) in stateList"
              :key="index"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
          <el-select v-model="equipType" placeholder="设备类型" class="utF setSe">
            <el-option
              v-for="item in equTypeOption"
              :key="item.equtypeid"
              :label="item.equtypename"
              :value="item.equtypeid">
            </el-option>
          </el-select>
          <el-button class="settingSearch" @click="handleSearch">搜索</el-button>
          <el-button class="settingReset" @click="handleReset">重置</el-button>
        </div>

        <!-- 单位表格列表 -->
        <div class="equip_table">
          <el-table
            stripe
            :data="equipList"
            style="width: 100%"
            ref="multipleTable"
            @selection-change="handleSelectionChange">
            <el-table-column
              type="selection"
              width="55">
            </el-table-column>
            <el-table-column
              label="序号"
              type="index">
            </el-table-column>
            <el-table-column
              label="设备状态"
              width="80"
              prop="equcfonf">
              <template slot-scope="scope">
                <el-tag type="danger" v-if="scope.row.equcfonf == 1">离线</el-tag>
                <el-tag type="success" v-if="scope.row.equcfonf == 2">在线</el-tag>
              </template>
            </el-table-column>
            <el-table-column
              label="公司名称"
              prop="companyname"
              show-overflow-tooltip>
            </el-table-column>
            <el-table-column
              label="设备型号"
              prop="equcode"
              show-overflow-tooltip>
            </el-table-column>
            <el-table-column
              label="设备IMEI"
              prop="equimei"
              show-overflow-tooltip>
            </el-table-column>
            <el-table-column
              label="设备类型"
              prop="equtypename">
            </el-table-column>
            <el-table-column
              label="设备地址"
              show-overflow-tooltip
              prop="adderss">
            </el-table-column>
            <el-table-column
              label="传输方式"
              prop="transmission_name">
            </el-table-column>
            <el-table-column
              label="上报时间"
              prop="equcfstime"
              show-overflow-tooltip>
            </el-table-column>
            <el-table-column
              label="操作"
              width="220">
              <template slot-scope="scope">
                <el-button type="text" size="small" @click="handleCadShow(scope.row)">CAD</el-button>
                <el-button type="text" size="small" @click="handleConfig(scope.row)">配置</el-button>
                <el-button v-if="roleBtnArray[2].power" type="text" size="small" @click="handleEdit(scope.row)">编辑</el-button>
                <el-button v-if="roleBtnArray[4].power" type="text" size="small" @click="handleMessage(scope.row)">报文</el-button>
                <el-button v-if="roleBtnArray[1].power" type="text" size="small" style="color:red;" @click="handleDelete(scope.row)">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
        <el-pagination
          @current-change="PageChange"
          :current-page="page"
          :page-sizes="[10]"
          :page-size="size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          class="settingPage">
        </el-pagination>
      </div>
    </div>

    <!-- CAD弹窗 -->
    <el-dialog
      title="CAD图纸"
      :visible.sync="dialogVisibleShow"
      width="900px"
      center
      class="gyDialog unit_addDialog" :close-on-click-modal="false" >
      <div class="cad_s" style="min-height: 600px;">
        <div class="cadImgBox">
          <img :src="CADUrl" alt="" >
          <span class="dian" :style="{top: y1 + 'px', left: x1 + 'px', display: active1}"></span>
        </div>
      </div>
    </el-dialog>

    <!--  新增-->
    <EquipAdded v-if="showAddDialog" :downArray="downArray" @closeAddFrom="closeAddFrom" @addSuccess="addSuccess"></EquipAdded>

    <!-- 编辑  -->
    <EquipEdit v-if="showEditDialog" :downArray="downArray" :formEdit="formEdit" @closeEditFrom="closeEditFrom" @editSuccess="editSuccess"></EquipEdit>

    <!--  报文  -->
    <EquipMsg v-if="showMsgDialog" :messageInfo="messageInfo" @closeMsg="closeMsg"></EquipMsg>

    <!--  配置  -->
    <EquipDeploy v-if="showDeployDialog" :deployRow="deployRow" @closeDeploy="closeDeploy"></EquipDeploy>
  </div>
</template>

<script>
import { geteqCount, geteqNum, getEquipType, getEquipList,deleteEquip, equipExport, insetMethod, tcpEquType, equFunction } from '@/http/api/equip'
import { nounSearch } from '@/http/api/settingSystem'
import { unitDownQuery } from '@/http/api/unit'
import EquipAdded from '@/components/setting/equipAdded.vue'
import EquipEdit from '@/components/setting/equipEdit.vue'
import EquipMsg from '@/components/setting/equipMsg.vue'
import EquipDeploy from '@/components/setting/equipDeploy.vue'
var echarts = require('echarts')
export default {
  props: ['roleBtn'],
  components: {
    EquipAdded,
    EquipEdit,
    EquipMsg,
    EquipDeploy
  },
  data () {
    return {
      equcount: 0,
      seriesArray: [],
      xAxisData: [],
      data1: [],
      data2: [],
      roleBtnArray: [],
      deleteBtnShow: false,
      deleteListId: [], // 多选id列表
      equname: '',
      transmission: '',
      tranList: [],
      equipState: '',
      stateList: [{ label: '全部状态', value: '' }, { label: '在线', value: 2 }, { label: '离线', value: 1 }],
      equipType: '',
      equTypeOption: [],
      equipList: [], // 设备列表
      page: 1,
      size: 10,
      total: 0,
      dialogVisibleShow: false,
      CADUrl: '',
      x1: '',
      y1: '',
      active1: 'block',
      showAddDialog: false,
      showEditDialog: false,
      downArray: {
        methodOption: [], // 接入方式
        transferOption: [], // 传输方式
        equipTypeOption: [], // 设备类型
        equipFactory: [], // 设备厂家
        cameraOption: [], // 摄像头厂家
        tcpEquTypeOption: [], // tcp设备类型
        equipFunction: [],   // AI设备功能
        companyList: []  // 绑定单位
      },
      formEdit: {},
      showMsgDialog: false,
      messageInfo: {},
      showDeployDialog: false,
      deployRow: {}
    }
  },
  beforeMount () {
    if (this.roleBtn.length > 0) {
      this.roleBtnArray = this.roleBtn
      // console.log(this.roleBtnArray)
    }
  },
  mounted () {
    this.getEqTypeCount()
    this.getEquipNn()
  },
  created () {
    this.companyid = JSON.parse(window.localStorage.getItem('loginInfo')).companyid
    this.getDropDown() // 选择框
    this.init()
  },
  methods: {
    /* 设备总数 */
    getEqTypeCount () {
      const params = { companyid: this.companyid }
      geteqCount(params).then(res => {
        if (res.status === '1') {
          this.equcount = res.data[0].equcount
          this.seriesArray[0] = { name: '在线', value: res.data[0].isequcfonf }
          this.seriesArray[1] = { name: '离线', value: res.data[0].noequcfonf }
          this.getEquipCount()
        }
      })
    },
    /* 在线离线-饼图 */
    getEquipCount () {
      let equipCountChart = echarts.init(this.$refs.eqCountChart)
      const option = {
        color: ['#FFC935', '#8479FF'],
        tooltip: {
          trigger: 'item',
          backgroundColor: 'rgba(255, 255, 255, 0.16)',
          borderColor: 'rgba(255, 255, 255, 0.16)',
          textStyle: { color: '#fffff' }
        },
        legend: {
          itemWidth: 10,
          itemHeight: 10,
          orient: 'vertical',
          bottom: '15%',
          right: '15%',
          data: this.seriesArray
        },
        series: [
          {
            name: '',
            type: 'pie',
            radius: ['30%', '70%'],
            avoidLabelOverlap: true,
            top: 'top',
            left: '-35%',
            itemStyle: {
              borderRadius: 2,
              borderColor: '#fff',
              borderWidth: 2
            },
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: '14'
              }
            },
            labelLine: {
              show: false
            },
            data: this.seriesArray
          }
        ]
      }
      equipCountChart.setOption(option)
      equipCountChart.on('mouseover', _ => {
        equipCountChart.dispatchAction({
          type: '',
          seriesIndex: 0,
          dataIndex: _.dataIndex
        })
      })
    },
    /* 设备数量 */
    getEquipNn () {
      const params = { companyid: this.companyid }
      geteqNum(params).then(res => {
        if (res.status === '1') {
          if (res.data.length > 0) {
            res.data.forEach(item => {
              this.xAxisData.push(item.equtypename)
              this.data1.push(item.isequcfonf)
              this.data2.push(item.noequcfonf)
            })
            this.getEquipNumber()
          }
        }
      })
    },
    /* 设备数量-柱状图 */
    getEquipNumber () {
      let equipNumChart = echarts.init(this.$refs.eqNumChart)
      var max = Math.max(...this.data1) + Math.max(...this.data2)
      if (max > 5) { max = Math.max(...this.data1) + Math.max(...this.data2) }
      else { max = 5 }
      const option = {
        tooltip: {
          trigger: 'axis',
          backgroundColor: 'rgba(255, 255, 255, 0.16)',
          borderColor: 'rgba(255, 255, 255, 0.16)',
          textStyle: { color: '#fffff' }
        },
        grid: {
          top: '15%',
          bottom: '3%',
          left: '3%',
          right: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: this.xAxisData,
          axisLabel: {
            color: '#6F7E90',
            interval: 0,
            rotate: -30,
            fontSize: 12
          },
          axisLine: {
            lineStyle: {
              color: '#6F7E90',
              width: 0.5,
              type: [2, 10],
              dashOffset: 2
            }
          },
          axisTick: { show: false }
        },
        yAxis: {
          type: 'value',
          max: max,
          axisLabel: { color: '#6F7E90' },
          splitLine: {
            lineStyle: {
              color: '#6F7E90',
              width: 0.5,
              type: [2, 10],
              dashOffset: 2
            }
          }
        },
        series: [
          {
            name: '在线',
            data: this.data1,
            type: 'bar',
            barWidth: '12px',
            stack: 'one',
            itemStyle: {
              color: '#FFC935'
            }
          },
          {
            name: '离线',
            data: this.data2,
            type: 'bar',
            barWidth: '12px',
            stack: 'one',
            itemStyle: {
              color: '#8479FF'
            }
          }
        ]
      }
      equipNumChart.setOption(option)
    },
    /* 下拉选择框 */
    getDropDown () {
      // 传输方式
      nounSearch({ config: 'transmission' }).then(res => {
        if (res.status === '1') { this.tranList = res.data }
        else { this.tranList = [] }
      })
      // 设备类型
      getEquipType().then(res => {
        if (res.status === '1') { this.equTypeOption = res.data.data }
        else { this.equTypeOption = [] }
      })
    },
    /* 设备列表查询 */
    init () {
      const params = {
        page_index: this.page,
        page_size: this.size,
        equname: this.equname,
        equtype: this.equipType,  // 类型
        equcfonf: this.equipState,  // 状态
        transmission: this.transmission,
        companyid: this.companyid
      }
      getEquipList(params).then(res => {
        if (res.status === '1') {
          this.deleteBtnShow = false
          if (res.data.data !== undefined) {
            this.equipList = res.data.data
            this.total = res.data.recordcount
          } else {
            this.equipList = []
            this.total = 0
          }
        } else {
          this.equipList = []
          this.total = 0
        }
      })
    },
    /* 搜索列表 */
    handleSearch () {
      this.page = 1
      this.init()
    },
    /* 重置列表 */
    handleReset () {
      this.page = 1
      this.equname = ''
      this.transmission = ''
      this.equipType = ''
      this.equipState = ''
      this.init()
    },
    PageChange (page) {
      this.page = page
      this.init()
    },
    /* 点击全选 */
    handleSelectionChange (val) {
      if (val.length === 0) {
        this.deleteBtnShow = false
        this.deleteListId = ''
      } else {
        this.deleteBtnShow = true
        this.deleteListId = val
      }
    },
    /* 多选删除 */
    deleteSelect () {
      var arr = []
      for (let i = 0; i < this.deleteListId.length; i++) {
        arr.push(this.deleteListId[i].equid)
      }
      var comId = arr.join(',')
      this.$confirm('此操作将永久删除所选信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        deleteEquip({ equid: comId }).then(res => {
          this.$message.success(res.message)
          this.init()
        })
      }).catch(() => {})
    },
    /* 逐条删除 */
    handleDelete (row) {
      this.$confirm('此操作将永久删除该信息, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        deleteEquip({ equid: row.equid }).then(res => {
          this.$message.success(res.message)
          this.init()
        })
      }).catch(() => {})
    },
    /* 导出 */
    exportHand () {
      const params = {
        equname: this.equname,
        equcfonf: this.equipState,
        transmission: this.transmission,
        equtype: this.equipType
      }
      equipExport(params).then(res => {
        if (res.status === '1') {
          this.$message.success(res.message)
          window.open(res.data.filepath)
        } else {
          this.$message.error(res.message)
        }
      })
    },
    /* 展示cad */
    handleCadShow (row) {
      this.dialogVisibleShow = true
      this.CADUrl = row.equ_cadress
      let arr = []
      if (row.equ_cadpoint === '' || row.equ_cadpoint === null) { arr = [] }
      else { arr = row.equ_cadpoint.split(',') }
      this.x1 = arr[0]
      this.y1 = arr[1]
    },
    /* 下拉款 - 列表 */
    dropdownList () {
      insetMethod().then(res => {  /* 接入方式 */
        if (res.status === '1') {
          let result = res.data
          result.forEach(item => {
            if (item.type === '4') { item.disabled = true }
            else { item.disabled = false }
          })
          this.downArray.methodOption = result
        } else {
          this.downArray.methodOption = []
        }
      })
      this.downArray.transferOption = this.tranList // 传输方式
      this.downArray.equipTypeOption = this.equTypeOption // 设备类型
      // 设备厂家
      nounSearch({ config: 'manufacturer' }).then(res => {
        if (res.status === '1') { this.downArray.equipFactory = res.data }
        else { this.downArray.equipFactory = [] }
      })
      // 摄像头厂家
      nounSearch({ config: 'cameramace' }).then(res => {
        if (res.status === '1') { this.downArray.cameraOption = res.data }
        else { this.downArray.cameraOption = [] }
      })
      // tcp设备类型
      tcpEquType({}).then(res => {
        if (res.status === '1') { this.downArray.tcpEquTypeOption = res.data }
        else { this.downArray.tcpEquTypeOption = [] }
      })
      // ai设备功能
      equFunction().then(res => {
        if (res.status === '1') { this.downArray.equipFunction = res.data }
        else { this.downArray.equipFunction = [] }
      })
      // 绑定单位
      unitDownQuery().then(res => {
        if (res.status === '1') { this.downArray.companyList = res.data }
        else { this.downArray.companyList = [] }
      })
    },
    /* 新增--展示 */
    handleAdd () {
      this.dropdownList()
      this.showAddDialog = true
    },
    /* 新增--关闭 */
    closeAddFrom () {
      this.showAddDialog = false
    },
    /* 新增 - 成功 */
    addSuccess () {
      this.closeAddFrom()
      this.init()
    },
    /* 编辑 - 展示 */
    handleEdit (row) {
      this.dropdownList()
      this.formEdit = { ...row }
      this.showEditDialog = true
    },
    /* 编辑 - 关闭 */
    closeEditFrom () {
      this.showEditDialog = false
    },
    /* 编辑 - 提交成功 */
    editSuccess () {
      this.closeEditFrom()
      this.init()
    },
    /* 报文 - 展示 */
    handleMessage (row) {
      this.showMsgDialog = true
      this.messageInfo = { ...row }
    },
    /* 报文 - 关闭 */
    closeMsg () {
      this.showMsgDialog = false
    },
    /* 配置 - 展示 */
    handleConfig (row) {
      this.showDeployDialog = true
      this.deployRow = { ...row }
    },
    /* 配置 - 关闭 */
    closeDeploy () {
      this.showDeployDialog = false
    }
  }
}
</script>

<style scoped>
.equipListCont{
  overflow-y: scroll;
  scrollbar-width: none;  /*火狐兼容*/
}
.equipListCont::-webkit-scrollbar {
  width: 0px; /* 谷歌兼容 */
}
.equip_top {
  width: 100%;
  height: 280px;
  overflow: hidden;
  display: flex;
}
.equip_num {
  width: 270px;
  height: 280px;
  background: #fff;
}
.equip_count {
  width: calc(100% - 270px);
  height: 280px;
  background: #fff;
  margin-left: 30px;
}
.equip_content{
  width: 100%;
  position: relative;
  height: calc(100% - 280px);
  padding-top: 30px;
  box-sizing: border-box;
}
.equip_contentBox{
  width: 100%;
  /* height: 100%; */
  position: relative;
  background: #fff;
  padding: 30px;
  box-sizing: border-box;
  /* overflow: auto; */
}
.utF{
  margin: 0px 16px;
}
.eqTitle {
  font-size: 14px;
  line-height: 20px;
  color: #6F7E90;
  margin-left: 30px;
  margin-top: 30px;
}
.eqNumber {
  line-height: 45px;
  color: #000000;
  font-size: 32px;
  font-family: 'PingFang SC';
  font-weight: 800;
  margin-left: 30px;
}
.eqChart {
  width: 100%;
  height: 60%;
}
.cad_s {
  width: 860px;
  height: 610px;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
}
.cadImgBox{
  width: auto;
  height: auto;
  position: relative;
}
.cadImgBox img {
  width: 100%;
  height: 100%;
}
.dian{
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: absolute;
  background: red;
  top: 0;
  left: 0;
  display: none;
}
</style>
