<template>
<!-- 设备新增 -->
  <div class="box100">
    <el-dialog title="设备新增" :visible.sync="addDialog" width="600px" center class="gyDialog fromDialog" :close-on-click-modal="false" :before-close="newFormAddCancel" top="6vh">
      <div class="form_content">
        <div class="from_tab flexAC">
          <p>设备添加：</p>
          <el-select v-model="fromName" placeholder="下拉选择接入方式" style="width: 50%;">
            <el-option
              v-for="item in downArray.methodOption"
              :key="item.type"
              :label="item.name"
              :value="item.type"
              :disabled="item.disabled">
            </el-option>
          </el-select>
        </div>

        <!-- 新增表单 -->
        <div class="tabCont">
          <el-form :model="formadd" :rules="formaddRule" ref="FromAdd">
            <el-form-item v-if="fromName === '1'" label="设备IMEI/ID" prop="equimei">
              <el-input v-model="formadd.equimei"  placeholder="请输入设备IMEI/ID" @change="addImeiEvent"></el-input>
            </el-form-item>
            <el-form-item v-if="fromName === '1'" label="设备型号" prop="equcode">
              <el-input v-model="formadd.equcode"  placeholder="请输入设备型号" disabled></el-input>
            </el-form-item>
            <el-form-item v-if="fromName === '1'" label="设备类型" prop="equtype">
              <el-select v-model="formadd.equtype" placeholder="下拉选择设备类型" class="adSe" style="width: 100%;" disabled>
                <el-option
                  v-for="item in downArray.equipTypeOption"
                  :key="item.equtypeid"
                  :label="item.equtypename"
                  :value="item.equtypeid">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item v-if="fromName === '1'" label="传输方式">
              <el-select v-model="formadd.transmission" placeholder="下拉选择传输方式" style="width: 100%;">
                <el-option
                  v-for="item in downArray.transferOption"
                  :key="item.dictid"
                  :label="item.datavalue"
                  :value="item.dictid">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item v-if="fromName === '6'" label="设备厂家" prop="manufacturer">
              <el-select v-model="formadd.manufacturer" placeholder="下拉选择设备厂家" style="width: 100%;">
                <el-option v-for="item in downArray.equipFactory" :key="item.dictid" :label="item.datavalue" :value="item.dictid"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item v-if="fromName === '2' || fromName === '6'" label="设备序列号" prop="equimei">
              <el-input v-model="formadd.equimei"  placeholder="请输入设备序列号"></el-input>
            </el-form-item>
            <el-form-item v-if="fromName === '2' || fromName === '6'" label="验证码" prop="equcode">
              <el-input v-model="formadd.equcode"  placeholder="请输入验证码"></el-input>
            </el-form-item>
            <el-form-item v-if="fromName === '2' || fromName === '6'" label="Appkey" prop="headport">
              <el-input v-model="formadd.headport"  placeholder="请输入Appkey"></el-input>
            </el-form-item>
            <el-form-item v-if="fromName === '2' || fromName === '6'" label="Secret" prop="rtspport">
              <el-input v-model="formadd.rtspport"  placeholder="请输入Secret"></el-input>
            </el-form-item>
            <el-form-item v-if="fromName === '3'" label="视频编码ID" prop="equimei">
              <el-input v-model="formadd.equimei"  placeholder="请输入视频编码ID"></el-input>
            </el-form-item>
            <el-form-item v-if="fromName === '3'" label="密码" prop="equcode">
              <el-input v-model="formadd.equcode"  placeholder="请输入密码"></el-input>
            </el-form-item>
            <el-form-item v-if="fromName === '3'" label="设备厂家"  prop="equmace">
              <el-select v-model="formadd.equmace" placeholder="下拉选择设备厂家" style="width: 100%;">
                <el-option
                  v-for="(item) in downArray.cameraOption"
                  :key="item.datavalue"
                  :label="item.datavalue"
                  :value="item.datavalue">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item v-if="fromName === '5'" label="设备类型"  prop="equtype">
              <el-select v-model="formadd.equtype" placeholder="下拉选择设备类型"  style="width: 100%;" @change="ctpTypeChange">
                <el-option
                  v-for="item in downArray.tcpEquTypeOption"
                  :key="item.equtypeid"
                  :label="item.equtypename"
                  :value="item.equtypeid">
                </el-option>
              </el-select>
            </el-form-item>
            <div v-if="formadd.equtype === '9' && fromName === '1' || formadd.equtype === '9' && fromName === '5'">
              <label><span style="color:red;">* </span>消防水箱尺寸(单位：米)</label>
              <el-row style="display: flex;justify-content: space-between;padding-top:10px;">
                <el-col :span = '8'>
                  <el-form-item class="eqlong" style="display: flex;align-items:center;" label="长" prop="equlong"><el-input v-model="formadd.equlong" placeholder="请输入设备长度" style="width:100%;"  onkeyup="this.value= this.value.match(/\d+(\.\d{0,2})?/) ? this.value.match(/\d+(\.\d{0,2})?/)[0] : ''"></el-input></el-form-item>
                </el-col>
                <el-col :span = '8'>
                  <el-form-item class="eqlong" style="display: flex;align-items:center;padding-left:10px;" label="宽" prop="equwide"><el-input v-model="formadd.equwide" placeholder="请输入设备宽度" style="width:100%;"  onkeyup="this.value= this.value.match(/\d+(\.\d{0,2})?/) ? this.value.match(/\d+(\.\d{0,2})?/)[0] : ''"></el-input></el-form-item>
                </el-col>
                <el-col :span = '8'>
                  <el-form-item class="eqlong" style="display: flex;align-items:center;padding-left:10px;" label="高" prop="equhigh"><el-input v-model="formadd.equhigh" placeholder="请输入设备高度" style="width:100%;"  onkeyup="this.value= this.value.match(/\d+(\.\d{0,2})?/) ? this.value.match(/\d+(\.\d{0,2})?/)[0] : ''"></el-input></el-form-item>
                </el-col>
              </el-row>
            </div>
            <el-form-item v-if="fromName === '5'" label="设备型号" prop="equcode">
              <el-select v-model="formadd.equcode" placeholder="下拉选择设备型号"  style="width: 100%;" @change="ctpModelChange">
                <el-option
                  v-for="item in ctpModelOption"
                  :key="item.equcode"
                  :label="item.equcode"
                  :value="item.equcode">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item v-if="fromName === '5'" label="设备编号" prop="equimei">
              <el-input v-model="formadd.equimei"  placeholder="请输入设备编号" style="width: 90%;"></el-input>
              <div class="tipCont" v-show="imeiTip">
                <div class="tcBox">
                  <span class="triangle"></span>说明： 当选择消防主机联网设备，系统默认设备编号勿修改，若提示设备编号已存在，请刷新，该编号用于用传设备设备编码！当选择其他类型设备，输入正确的IMEI/ID，IMEI设备需提前导入
                </div>
              </div>
              <i class="el-icon-question codeTip" @click="showTip"></i>
            </el-form-item>
            <el-form-item v-if="fromName === '6'" label="设备功能" prop="equmark">
              <el-select v-model="formadd.equmark" multiple placeholder="下拉选择设备功能"  style="width: 100%;">
                <el-option
                  v-for="item in downArray.equipFunction"
                  :key="item"
                  :label="item"
                  :value="item">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item v-if="fromName === '3'" label="绑定单位" style="width: 100%;" prop="companyid">
              <el-select v-model="formadd.companyid" filterable placeholder="请选择单位" :disabled="isSuper" class="adSe" style="width: 100%" @change="changeCom">
                <el-option v-for="item in downArray.companyList" :key="item.index"
                           :label="item.companyname"
                           :value="item.companyid">
                  {{ item.companyname }}
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item v-if="fromName !== '3'" label="绑定单位" style="width: 100%;">
              <el-select v-model="formadd.companyid" filterable placeholder="请选择单位" :disabled="isSuper" class="adSe" style="width: 100%" @change="changeCom">
                <el-option v-for="item in downArray.companyList" :key="item.index"
                           :label="item.companyname"
                           :value="item.companyid">
                  {{ item.companyname }}
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item >
              <template slot="label">
                <span>栋</span>
                <span style="margin-left: 150px">层</span>
              </template>
              <div class="test_floor" style="width: 100%">
                <el-select v-model="formadd.equ_floor" placeholder="" class="adSe" style="width:150px;" @change="buildSelect">
                  <el-option
                    v-for="(item, index) in buildOption"
                    :key="index"
                    :label="item.buildname"
                    :value="item.buildname">
                  </el-option>
                </el-select>
                <el-select v-model="formadd.equ_layer" placeholder="" class="adSe" style="width:80px;margin:0 10px;" @change="floorSelect">
                  <el-option
                    v-for="(item, index) in floorOption"
                    :key="index"
                    :label="item.label"
                    :value="item.label">
                    {{ item.label }}
                  </el-option>
                </el-select>
                <el-input v-model="formadd.equ_adderss" style="width:150px;margin: 0px 8px 0px 12px;"  placeholder="具体设备位置" ></el-input>
                <el-button style="width:70px;padding: 0;margin-left:10px;" class="settingAdd" @click="changeCAD">关联图纸</el-button>
              </div>
            </el-form-item>
            <div class="test_point">
              <label class="from_label">设备图片</label>
              <Upload ref="uploadImg" :fileType="'jpg/png/jpeg'" :refresh="refresh" @uploadSuccess="addImgSuccess"></Upload>
              <p class="img_tip">支持.jpg.png.jpeg 格式</p>
            </div>
          </el-form>
          <div class="flexCE">
            <div class="settingCancel" @click="newFormAddCancel">取消</div>
            <div class="settingConfirm" @click="newFormAddHand">确定</div>
          </div>
        </div>
      </div>

      <!-- 新增 cad弹框 -->
      <el-dialog
        append-to-body
        title="图纸定位"
        :visible.sync="cadDialogVisible"
        width="910px"
        center
        :before-close="handleCadClose"
        class="gyDialog" :close-on-click-modal="false" >
        <div class="cad_p">
          <div class="cadImgBox">
            <img :src="CADPosition" alt="" @click="handleAddCAD($event)">
            <span class="dian" :style="{top: y + 'px', left: x + 'px', display: active}"></span>
          </div>
        </div>
        <div class="flexCE">
          <div class="settingCancel" @click="handleCadClose">取消</div>
          <div class="settingConfirm" @click="handCadConfirm">确定</div>
        </div>
      </el-dialog>
    </el-dialog>
  </div>
</template>

<script>
import { getEquipInfo, tcpModelType, tcpCodeQuery, addEquip } from '@/http/api/equip'
import { dropbuilding } from '@/http/api/build'
import Upload from '@/components/upload.vue'
export default {
  name: 'equipAdded',
  components: {
    Upload
  },
  props: ['downArray'],
  data () {
    const equimeiCJY = (rule, value, callback) => {
      if (value === '' && this.formadd.type === '1') {
        return callback(new Error('设备IMEI/ID不能为空'))
      } else if (value === '' && this.formadd.type === '2') {
        return callback(new Error('设备序列号不能为空'))
      } else if (value === '' && this.formadd.type === '3') {
        return callback(new Error('视频编码ID不能为空'))
      } else if (value === '' && this.formadd.type === '5') {
        return callback(new Error('设备编号不能为空'))
      } else if (value === '' && this.formadd.type === '6') {
        return callback(new Error('设备序列号不能为空'))
      }
      callback()
    }
    const equcodeJy = (rule, value, callback) => {
      if (value === '' && this.formadd.type === '1') {
        return callback(new Error('设备型号不能为空'))
      } else if (value === '' && this.formadd.type === '2') {
        return callback(new Error('验证码不能为空'))
      } else if (value === '' && this.formadd.type === '3') {
        return callback(new Error('密码不能为空'))
      } else if (value === '' && this.formadd.type === '5') {
        return callback(new Error('设备型号不能为空'))
      } else if (value === '' && this.formadd.type === '6') {
        return callback(new Error('验证码不能为空'))
      }
      callback()
    }
    const equTypeJy = (rule, value, callback) => {
      if (this.formadd.type === '1') {
        if (value === '') { return callback(new Error('设备类型不能为空')) }
      } else if (value === '' && this.formadd.type === '5') {
        return callback(new Error('设备类型不能为空'))
      }
      callback()
    }
    return {
      companyid: '',
      addDialog: true,
      fromName: '1',
      formadd: {
        type: '',
        companyid: '',
        equ_floor: '',
        equ_layer: '',
        equ_adderss: '',
        equ_cadress: '',
        equ_cadpoint: '',
        transmission: 1, // 传输方式
        equimei: '',  // imei/序列号
        equcode: '',  // 型号/验证码
        equtype: '',  // 设备类型
        headport: '', // Appkey
        rtspport: '', // Secret
        equmace: '',  // 设备摄像机厂家
        equ_image: '',
        manufacturer: '', // 设备厂家
        equmark: '', // 设备功能
        equlong: '',
        equwide: '',
        equhigh: ''
      },
      formaddRule: {
        equimei: [{ required: true, validator: equimeiCJY, trigger: 'blur' }],
        equcode: [{ required: true, validator: equcodeJy, trigger: 'blur' }],
        equtype: [{ required: true, validator: equTypeJy, trigger: 'blur' }],
        headport: [{ required: true, trigger: 'blur', message: 'Appkey不能为空' }],
        rtspport: [{ required: true, trigger: 'blur', message: 'Secret不能为空' }],
        companyid: [{ required: true, trigger: 'blur', message: '单位不能为空' }],
        equmace: [{ required: true, trigger: 'blur', message: '设备厂家不能为空' }],
        manufacturer: [{ required: true, trigger: 'blur', message: '设备厂家不能为空' }],
        equmark: [{ required: true, trigger: 'blur', message: '设备功能不能为空' }],
        equlong: [{ required: true, trigger: 'blur', message: '设备长度不能为空' }],
        equwide: [{ required: true, trigger: 'blur', message: '设备宽度不能为空' }],
        equhigh: [{ required: true, trigger: 'blur', message: '设备高度不能为空' }]
      },
      ctpModelOption: [], // 设备型号
      imeiTip: false,
      isSuper: true,
      selectCompanyId: '',
      floorOption: [], // 楼层
      // companyList: [],  // 单位
      buildOption: [],  // 建筑 栋
      cadDialogVisible: false,
      CADPosition: '',
      x: 0,
      y: 0,
      active: 'none',
      refresh: false,
    }
  },
  created () {
    this.companyid = JSON.parse(window.localStorage.getItem('loginInfo')).companyid
    this.selectCompanyId = this.companyid
    if (this.companyid === '') {
      this.isSuper = false
    } else { this.isSuper = true }
  },
  mounted () {
  },
  methods: {
    /* 新增IMEI/ID事件 */
    addImeiEvent () {
      getEquipInfo({ equbacode: this.formadd.equimei }).then(res => {
        if (res.status === '1') {
          if (res.data.data !== '') {
            this.formadd.equcode = res.data[0].equbamodel
            this.formadd.equtype = res.data[0].equtypeid
          } else {
            this.$message.warning('该IMEI/ID未查询到设备信息')
          }
        }
      })
    },
    /* ctp设备类型选择 */
    ctpTypeChange (val) {
      this.formadd.equcode = ''
      tcpModelType({ equtype: val }).then(res => {
        if (res.status === '1') {
          this.ctpModelOption = res.data
        } else {
          this.ctpModelOption = []
        }
      })
    },
    /* ctp设备型号选择 */
    ctpModelChange (val) {
      tcpCodeQuery({ equtype: this.formadd.equtype, equcode: val }).then(res => {
        if (res.status === '1') {
          this.formadd.equimei = res.data.equimei
        }
      })
    },
    showTip () {
      this.imeiTip = !this.imeiTip
    },
    /* 单位绑定 添加 */
    changeCom (val) {
      this.selectCompanyId = val
      this.formadd.equ_floor = ''
      this.formadd.equ_layer = ''
      this.formadd.equ_adderss = ''
      this.floorOption = []
      this.buildDrop()
    },
    /* 建筑下拉 */
    buildDrop () {
      dropbuilding({ companyid: this.selectCompanyId }).then(res => {
        if (res.status === '1') {
          this.buildOption = res.data
        } else {
          this.buildOption = []
        }
      })
    },
    /* 新增栋层选择 */
    buildSelect (val) {
      this.formadd.equ_layer = ''
      this.formadd.equ_adderss = ''
      this.floorOption = this.test(val, this.buildOption)
    },
    test (str, arr) {
      let s = ''
      arr.forEach(element => {
        if (str === element.buildname) {
          s = element.buildmark
        }
      })
      return s
    },
    /* 层选择 */
    floorSelect (val) {
      this.floorOption.forEach((item) => {
        if (item.label === val) {
          this.CADPosition = item.path
        }
      })
    },
    /* cad 展示-图纸关联 */
    changeCAD () {
      if (this.formadd.equ_floor !== '' && this.formadd.equ_layer !== '') {
        this.cadDialogVisible = true
      } else {
        this.$message.warning('请选择栋/层')
      }
    },
    handleAddCAD () {
      this.active = 'block'
      this.x = event.offsetX
      this.y = event.offsetY
      this.formadd.equ_cadpoint = this.x + ',' + this.y
    },
    handleCadClose () {
      this.cadDialogVisible = false
    },
    /* cad定位确认 */
    handCadConfirm () {
      if (this.formadd.equ_cadpoint !== '') {
        this.cadDialogVisible = false
      } else {
        this.$message.warning('还未选择设备安装点位')
      }
    },
    addImgSuccess (res) {
      this.formadd.equ_image = res.filepath
    },
    /* 取消 */
    newFormAddCancel () {
      this.$emit('closeAddFrom')
    },
    /* 新增提交 */
    newFormAddHand () {
      this.formadd.type = this.fromName
      this.formadd.equ_cadress = this.CADPosition
      let equmark = ''
      if (this.formadd.equmark !== '' || this.formadd.equmark.length > 0) {
        equmark = this.formadd.equmark.join(',')
      } else {
        equmark = ''
      }
      this.$refs.FromAdd.validate((valid) => {
        if (!valid) return
        const params = {
          type: this.formadd.type,
          companyid: this.formadd.companyid,
          equ_floor: this.formadd.equ_floor,
          equ_layer: this.formadd.equ_layer,
          equ_adderss: this.formadd.equ_adderss,
          equ_cadress: this.formadd.equ_cadress,
          equ_cadpoint: this.formadd.equ_cadpoint,  // cad点位
          transmission: this.formadd.transmission,  // 传输方式
          equimei: this.formadd.equimei,  // IMEI
          equcode: this.formadd.equcode,  //
          equtype: this.formadd.equtype,  // 设备类型
          headport: this.formadd.headport,
          rtspport: this.formadd.rtspport,
          equmace: this.formadd.equmace,  // 设备摄像机厂家
          equ_image: this.formadd.equ_image, // 设备图片
          manufacturer: this.formadd.manufacturer,
          equmark: equmark,
          equlong: this.formadd.equlong,
          equwide: this.formadd.equwide,
          equhigh: this.formadd.equhigh,
        }
        addEquip(params).then(res => {
          if (res.status === '1') {
            this.$message.success(res.message)
            this.$emit('addSuccess')
          } else {
            this.$message.error(res.message)
          }
        })
      })
    }
  }
}
</script>

<style scoped>
.el-dialog__wrapper {
  overflow-y: scroll;
  scrollbar-width: none;  /*火狐兼容*/
}
.el-dialog__wrapper::-webkit-scrollbar {
  width: 0px; /* 谷歌兼容 */
}
.from_tab{
  width: 100%;
  position: relative;
}
.tabCont{
  padding: 20px 0px 0px;
}
.eqlong >>> .el-form-item__label:before{
  display: none;
}
.codeTip{
  font-size: 30px;
  box-sizing: border-box;
  color: #196DF7;
  position: absolute;
  bottom: 3px;
  right: 10px;
  cursor: pointer;
}
.tipCont{
  width: 100%;
  height: 80px;
  position: absolute;
  bottom: 40px;
}
.tcBox{
  width: 100%;
  height: 100%;
  position: relative;
  background: #4295FF;
  color: #fff;
  padding: 10px;
  box-sizing: border-box;
  line-height: 20px;
  border-radius: 5px;
}
.triangle{
  width: 0px;
  height: 0px;
  position: absolute;
  line-height: 0px;
  right: 20px;
  bottom: -8px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 10px solid rgb(66 149 255);
}
.test_floor {
  width: 390px;
  position: relative;
  top: 0;
  left: 0;
  margin-top: 24px;
}
.cad_p {
  width: 860px;
  height: 600px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cadImgBox{
  width: auto;
  height: auto;
  position: relative;
}
.cadImgBox img {
  width: 100%;
  height: 100%;
}
.dian{
  width: 10px;
  height: 10px;
  border-radius: 50%;
  position: absolute;
  background: red;
  top: 0;
  left: 0;
  display: none;
}
</style>
