<template>
  <!-- 数据采集传输终端 -->
  <div class="box100" v-if="detailInfo.equtype === '22'">
    <div class="flexAC" style="margin: 12px 0px;">
      <div class="udi_k"></div>
      <p class="udi_t">关联设备</p>
    </div>
    <div class="eq_table">
      <el-table
        stripe
        :data="tableData"
        style="width: 100%"
        max-height="200">
        <el-table-column
          label="通道号"
          prop="terchan"
          width="100">
        </el-table-column>
        <el-table-column
          label="通道类型"
          prop="tertype"
          width="150">
        </el-table-column>
        <el-table-column
          label="设备类型"
          prop="equtypename">
          <template slot-scope="scope">
            <template v-if="scope.row.equtypename !== ''">
              <span>{{scope.row.equtypename}}</span>
              <el-tag class="on_line" v-if="scope.row.isadd == true">已添加</el-tag>
              <el-tag class="off_line" v-if="scope.row.isadd == false">未添加</el-tag>
            </template>
            <template v-if="scope.row.equtypename === ''">
              <span>{{scope.row.equtypename}}</span>
            </template>
          </template>
        </el-table-column>
        <el-table-column
          label="设备号"
          prop="tercode">
        </el-table-column>
        <el-table-column
          label="操作"
          width="100">
          <template slot-scope="scope">
            <el-button type="text" size="small" style="color: #FF944C;" @click="handleEdit(scope.row)" v-if="scope.row.equtypename != ''">修改</el-button>
            <el-button type="text" size="small" style="color: #196DF7;" @click="handlBind(scope.row)"  v-if="scope.row.equtypename == ''">绑定</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- 修改弹窗 -->
    <el-dialog title="绑定" append-to-body :visible.sync="bindShow" width="450px" center class="gyDialog" :close-on-click-modal="false" :before-close="bindFromCancel">
      <el-form :model="bindfrom" :rules="formRules" ref="bindFrom">
        <el-form-item label="通道号"  prop="terchan">
          <el-input v-model="bindfrom.terchan" disabled  placeholder="请输入通道号"></el-input>
        </el-form-item>
        <el-form-item label="通道类型"  prop="tertype">
          <el-input v-model="bindfrom.tertype" disabled  placeholder="请输入通道号"></el-input>
        </el-form-item>
        <el-form-item label="设备类型">
          <el-select v-model="bindfrom.equtypename" clearable  placeholder="请选择设备类型" style="width: 400px"  @change="handlwEq"  @clear="setValueNull">
            <el-option
              v-for="item in equtypeOptions"
              :key="item.equtypeid"
              :label="item.equtypename"
              :value="item.equtypeid">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="设备号"  prop="tercode">
          <el-input v-model="bindfrom.tercode" disabled  placeholder="请输入设备号"></el-input>
        </el-form-item>
      </el-form>
      <div class="flexCE">
        <div class="settingCancel"  @click="bindFromCancel">取消</div>
        <div class="settingConfirm" @click="bindFromConfirm">确定</div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getErminAlasso, updateTerminAlasso, getEquipType } from '@/http/api/equip'

export default {
  props: ['detailInfo', 'dataTer'],
  data () {
    return {
      tableData: [],
      bindShow: false,
      bindfrom: {
        terchan: '',
        equtypename: '',
        tercode: ''
      },
      formRules: {
        terchan: [{ required: true, trigger: 'blur', message: '通道号不能为空' }],
        // equtypename: [{ required: true, trigger: 'blur', message: '设备类型不能为空' }],
        tercode: [{ required: true, trigger: 'blur', message: '设备号不能为空' }],
        tertype: [{ required: true, trigger: 'blur', message: '通道类型不能为空' }],
      },
      equtypeOptions: []
    }
  },
  watch: {
    dataTer (val) {
      if (this.detailInfo.equtype === '22') {
        this.init()
      }
    }
  },
  mounted () {
    this.init()
  },
  methods: {
    init () {
      getErminAlasso({equid: this.detailInfo.equid}).then(res => {
        if (res.status === '1') {
          this.tableData = res.data
        } else {
          this.tableData = []
        }
      })
    },
    getInit () {
      // 设备类型
      getEquipType({}).then(res => {
        if (res.status === '1') {
          this.equtypeOptions = res.data.data
        } else {
          this.equtypeOptions = []
        }
      })
    },
    handlwEq (val) {
      this.equtypeOptions.forEach((item) => {
        if (item.equtypeid === val) {
          this.bindfrom.equtype = item.equtypeid
          console.log(this.bindfrom.equtype)
        }
      })
    },
    setValueNull (val) {
      this.bindfrom.equtype = ''
    },
    handlBind (row) {
      this.bindShow = true
      this.bindfrom = row
      this.getInit()
    },
    bindFromCancel () {
      this.bindShow = false
      this.$refs['bindFrom'].resetFields()
      this.init()
    },
    bindFromConfirm () {
      this.$refs.bindFrom.validate((valid) => {
        if (!valid) return
        const params = {
          equid: this.detailInfo.equid,
          terid: this.bindfrom.terid,
          equtype: this.bindfrom.equtype
        }
        updateTerminAlasso(params).then(res => {
          if (res.status === '1') {
            this.$message.success(res.message)
            this.bindFromCancel()
            this.init()
          } else {
            this.$message.error(res.message)
          }
        })
      })
    },
    // 修改
    handleEdit (row) {
      if (row.isadd == false) {
        this.bindShow = true
        this.bindfrom = row
        this.getInit()
      } else {
        this.$confirm('如需进行修改，先在相关设备列表删除已添加的设备才能进行下一步操作。', '修改', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
          center: true
        }).then(() => {
          // const params = {
          //   equid: this.detailInfo.equid,
          //   terid: row.terid,
          //   equtype: row.equtype,
          // }
          // updateTerminAlasso(params).then(res => {
          //   if (res.status === '1') {
          //     this.$message.success(res.message)
          //     this.init()
          //   } else {
          //     this.$message.error(res.message)
          //   }
          // })
        }).catch(() => {})
      }
    }
  }
}
</script>

<style scoped>
.eq_table .el-table {
  color: #333333;
  /* font-weight: bold; */
  /* font-size: 16px; */
}
.eq_table .el-table thead th,.eq_table .el-table thead tr{
  background: transparent;
  color: #333333;
}
.eq_table .el-table th, .el-table tr{
  background: transparent;
  color: #333333;
}
.eq_table .el-table::before {
  height: 0;
}
.eq_table .el-table td, .eq_table .el-table th.is-leaf{
  border: none;
}
.el-table td, .el-table th.is-leaf {
  border-bottom: none !important;
}
/* .eq_table .el-table tbody tr:nth-child(odd) {
  background: #FFFFFF !important;
}
.eq_table .el-table tbody tr:nth-child(even) {
  background: #F6F9FF !important;
} */
.el-tag {
  margin-left: 5px;
  background-color: transparent;
  color: #FFFFFF;
}
.on_line {
  width: 44px;
  height: 24px;
  background: rgba(31, 219, 138, 0.2);
  border: 1px solid rgba(31, 219, 138, 0.2);
  opacity: 1;
  border-radius: 4px;
  color: #1FDB8A;
  line-height: 24px;
  text-align: center;
  padding: 0px;
}
.off_line {
  width: 44px;
  height: 24px;
  background: rgba(130, 145, 161, 0.2);
  border: 1px solid rgba(130, 145, 161, 0.2);
  opacity: 1;
  border-radius: 4px;
  color: #8291A1;
  line-height: 24px;
  text-align: center;
  padding: 0px;
}
</style>
